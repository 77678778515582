import common from '@/lang/en/modules/common'
import errors from '@/lang/en/modules/errors'
import loginPage from '@/lang/en/modules/loginPage'
import maintenance from '@/lang/en/modules/maintenance'
import passwordPage from '@/lang/en/modules/passwordPage'
import signupPage from '@/lang/en/modules/signupPage'
import verifyPage from '@/lang/en/modules/verifyPage'

export default {
  common,
  errors,
  maintenance,
  loginPage,
  passwordPage,
  signupPage,
  verifyPage,
}
