export default {
  recovery: {
    title: 'Password recovery',
    description:
      'To change your password, please enter your email address. A link to the password change page will be sent to this email. For security reasons, it will only be valid for 60 minutes.',
    buttonText: 'Get a recovery link',
  },
  new: {
    title: 'Create new password',
    description: 'Choose a new password for your account.',
    buttonText: 'Set new password',
  },
  sent: {
    title: 'Password recovery link sent',
    description:
      'We’ve sent the instructions to reset your password to *{email}*',
    buttonText: 'Back to Log in',
  },
  success: {
    title: 'Password has been changed',
    description: 'You can use it to login.',
    buttonText: 'Log in',
  },
  failed: {
    title: 'Password recovery failed',
    description:
      'Password recovery link, either invalid or expired.\n Please request another one on the password recovery page.',
    buttonText: 'Back to Password recovery',
  },
}
