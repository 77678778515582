import { CryptoCurrency } from '@/app/types/app/crypto'

export enum EmailApi {
  Resend = '/api/email/resend',
  CheckEmailVerification = '/api/email/verified',
}

export enum AuthApi {
  Login = '/api/auth/login',
  Logout = '/api/auth/logout',
  Signup = '/api/auth/register',
  ForgotPassword = '/api/auth/password/email',
  ResetPassword = '/api/auth/password/reset',
  CheckResetToken = '/api/auth/password/check-token',
}

export enum UsersApi {
  FetchUser = '/api/users/me',
}

export interface User {
  id: number
  email: string
  username: string
  email_verified_at: string | null
  analytics_id: string
}

export interface EmailVerificationResponse {
  verified: boolean
}

export interface Balance {
  amount: number
  pendingReceived: number
  fiatAmount: number
  currency: CryptoCurrency
}

export interface UserResponse {
  user: User
  balances: Balance[]
}

export enum SignupType {
  Paygate = 'payment_gateway',
  Auth = 'auth',
}

export interface AuthRequestBody {
  email: string
  password: string
}

export interface SignupSimpleRequestBody {
  email: string
  username: string
  password: string
  password_confirmation: string
  registration_type: SignupType
  referral_token: string
}

export interface SignupRequestBody extends SignupSimpleRequestBody {
  commercial_data: SignupCommercialData
}

export interface SignupCommercialData {
  company_name: string
  monthly_turnover: MonthlyTurnover
  contact_phone: string
  social_contact: string
}

export enum MonthlyTurnover {
  LessThan100k = '<100k',
  From100kTo1M = '100k-1M',
  From1MTo10M = '1M-10M',
  MoreThan10M = '>10M',
}

export interface AuthResponse {
  access_token: string
  user: User
}

export interface RecoveryData {
  token: string
  email: string
  password: string
  password_confirmation: string
}
