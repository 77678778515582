export default {
  baseURL: process.env.VUE_APP_AXIOS_BASE_URL ?? '',
  axiosTimeout: process.env.VUE_APP_AXIOS_BASE_TIMEOUT ?? 20000,
  dashboardUrl: process.env.VUE_APP_DASHBOARD_URL ?? '',
  landingUrl: process.env.VUE_APP_LANDING_URL ?? '',
  cookieName: process.env.VUE_APP_COOKIE_NAME ?? '',
  domain: process.env.VUE_APP_ROOT_DOMAIN ?? '',
  contactEmail: 'support@inxy.io',
  demoLink: 'https://calendar.app.google/AJc56GVUJmGUGEj6A',
}
