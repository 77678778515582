export default {
  pageTitles: {
    login: 'INXY Payments Login',
    signup: 'INXY Payments Signup',
    verification: 'Email verification',
    passwordRecovery: 'Password recovery',
  },
  messages: {
    logout: 'Log out',
    forgotPassword: 'Forgot password?',
    backTo: 'Back to',
    inxy: 'pay.inxy.io/u',
    sendEmailTo: 'Send email to',
    bookDemoHere: 'Book a demo',
    here: 'here',
    or: 'or',
  },
  labels: {
    email: 'Email',
    password: 'Password',
    username: 'Username',
    newPassword: 'New password',
    companyName: 'Company name',
    turnover: 'Monthly turnover',
    phone: 'Phone or WhatsApp number',
    social: 'Telegram or LinkedIn account',
  },
  buttons: {
    signup: 'Sign up',
    login: 'Log in',
  },
}
