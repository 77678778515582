import { RouteRecordRaw } from 'vue-router'

import { Layout } from '@/app/types/app/common'
import { RouteName, RoutePath } from '@/app/types/app/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: RoutePath.Login,
  },
  {
    path: RoutePath.Login,
    name: RouteName.Login,
    component: () => import('@/views/login.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: RoutePath.Signup,
    name: RouteName.Signup,
    component: () => import('@/views/ViewSignup/ViewSignup.vue'),
    meta: { layout: Layout.Empty },
  },
  {
    path: RoutePath.SignupSimple,
    name: RouteName.SignupSimple,
    component: () => import('@/views/ViewSignup/ViewSignupSimple.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: RoutePath.EmailVerify,
    name: RouteName.EmailVerify,
    component: () => import('@/views/email/index.vue'),
    meta: { canLogout: true, layout: Layout.Default },
  },
  {
    path: RoutePath.EmailVerifyFailed,
    name: RouteName.EmailVerifyFailed,
    component: () => import('@/views/email/index.vue'),
    meta: { canLogout: true, layout: Layout.Default },
  },
  {
    path: RoutePath.EmailVerifyToken,
    name: RouteName.EmailVerifyToken,
    component: () => import('@/views/email/verify/_id/_token.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: RoutePath.PasswordRecovery,
    name: RouteName.PasswordRecovery,
    component: () => import('@/views/password/index.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: RoutePath.PasswordRecoveryNew,
    name: RouteName.PasswordRecoveryNew,
    component: () => import('@/views/password/index.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: RoutePath.PasswordRecoverySent,
    name: RouteName.PasswordRecoverySent,
    component: () => import('@/views/password/status.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: RoutePath.PasswordRecoverySuccess,
    name: RouteName.PasswordRecoverySuccess,
    component: () => import('@/views/password/status.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: RoutePath.PasswordRecoveryFailed,
    name: RouteName.PasswordRecoveryFailed,
    component: () => import('@/views/password/status.vue'),
    meta: { layout: Layout.Default },
  },
  {
    path: RoutePath.PasswordReset,
    name: RouteName.PasswordReset,
    component: () => import('@/views/password/reset.vue'),
    meta: { layout: Layout.Default },
  },
  {
    name: RouteName.Maintenance,
    path: '/maintenance',
    component: () => import('@/views/ServiceViews/ViewMaintenance.vue'),
    meta: { layout: Layout.Default },
  },

  // 404
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    redirect: RoutePath.Login,
  },
]

export default routes
