export default {
  required: 'This field is required',
  passTemplate:
    'Password must be at least 8 characters with 1 uppercase letter, 1 lowercase and 1 number',
  usernameTemplate:
    'This username is invalid. It must be 5–15 symbols long and cannot contain special characters, except for low line',
  emailTemplate: 'Please enter a valid email address',
  phoneTemplate: 'Please enter a valid phone number',
  emailNotVerified: 'Your email address is not verified',
  serviceUnavailable:
    'Planned maintenance in progress. Some features unavailable.',
}
