import { createI18n } from 'vue-i18n'

import { Locales } from '@/app/types/app/translation/locales'

import en from '@/lang/en'

export default createI18n({
  locale: Locales.En,
  fallbackLocale: Locales.En,
  allowComposition: true,
  messages: { en },
})
