export default {
  title: 'Email verification',
  titleFailed: 'Email verification failed',
  description:
    'We’ve sent you a confirmation link to *{email}*.\n Please click it to start using INXY Payments. The link is only valid for 1 hour.',
  descriptionFailed:
    'Confirmation request expired,\n please generate a new  confirmation link',
  buttonText: 'Send the link again',
  buttonTextSuccess: 'The link has been resent',
}
