export default {
  title: 'Create your INXY account',
  titleSimple: 'Welcome to INXY Payments!',
  titleExtendedMobile: 'Welcome to the new way\n to legally pay with crypto!',
  titleExtendedDesktop:
    'Welcome to the new way to legally pay with digital currencies!',
  formTip: 'Already registered?',
  usernameCantBeChanged:
    'A unique identifier\nthat will be used\nfor creating payment links.\nOnce set, it cannot be changed',
  steps: {
    title: ' Start in no time with 3 easy steps:',
    one: 'Sign up: fill out the form with your company details',
    two: 'Pass easy and streamlined KYB process',
    three:
      'If needed, request sandbox access from our\n support team to proceed with integration',
  },
  securityNote: 'Your security - Our priority',
  contactSupport: 'Contact our support for help on any stage:',
}
